import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StandaloneContent from "../containers/standalone-pages";
import StandaloneBanner from '../components/banner/banner';


const AcademicPage = (props: any) => {
  const { data } = props;
  return (
    <Layout do_footy={false}>
      <SEO
        title="Academic"
        description="Academic summary"
        image={data.file ? data.file.publicURL : null}
      />
      <StandaloneBanner
          src={data.file}
      />
      <StandaloneContent
        greeting={'It all started'}
        body={[
            'During my academic journey from 2013 to 2020, I pursued both a Bachelor\'s and Master\'s degree in Technical Medicine at the University of Twente in the Netherlands. This intensive program, spanning six years, integrates engineering principles with medical knowledge and clinical practice, culminating in licensure as a technical physician and coordinating radiation protection expert.',
            'The curriculum is a fusion of science, technology, and clinical application. In the Bachelor\'s phase, courses ranged from cell biology to spatial mathematics, providing a solid foundation in anatomy, physiology, and medical sensing. Advanced studies in the Master\'s phase delved into specialized areas such as radiation expertise, molecular interactions for imaging, and clinical biotechnology, preparing me for complex medical challenges.',
            'In 2018, I first moved to Japan for a minor in engineering and economics at Saitama University in Japan. Then, I gained invaluable clinical and research experience through internships and research affiliations in Boston, Massachusetts at renowned institutions like MIT and  Harvard Medical School.',
            'In 2020 I moved to Amsterdam to pursue my PhD in Amsterdam.',
        ]}
        socials={['resume', 'github', 'linkedin', 'orcid']}
        greet={true}
      />
    </Layout>
  );
};

export default AcademicPage;

export const pageQuery = graphql`
query {
    file(
      sourceInstanceName: { eq: "assets" }
      base: { eq: "academia.jpg" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;